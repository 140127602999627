import React from "react";
import { Layout } from "../components/Layout";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import { SEO } from "../components/SEO";

const Page = () => {
    return (
        <>
            <SEO title={"Privacy Policy"} lang={"en"} meta={[]} />
            <Layout label={"Fairy World Quest - Privacy Policy"}>
                <PrivacyPolicy />
            </Layout>
        </>
    );
};


export default Page;
